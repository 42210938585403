@import "../../theme/theme"

.wrapper
  width: 100vw
  height: 100vh
  background-color: #1b1c1e
  top: 0
  left: 0
  bottom: 0
  right: 0
  position: absolute
  z-index: -1
  opacity: 0
  transition: opacity 0s ease-in-out

.sider
  background-color: $white
  padding: 2rem 1rem 1rem
  position: fixed
  z-index: 2
  //box-shadow: 0 .1rem .1rem $shadow-color

  &.bottom
    left: 0
    right: 0
    bottom: -1000px
    border-top-left-radius: $border-radius-big
    border-top-right-radius: $border-radius-big
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    transition: bottom .2s ease-in-out

    &.active
      bottom: 0

  &.left
    left: 0
    top: 0
    bottom: 0
    margin-left: -100vw
    transition: margin-left .2s ease-in-out

    .content
      left: 0
      top: 0
      bottom: 0
      z-index: 10
      width: 100vw
      padding: 1rem
      position: absolute
      background-color: $white

    &.active
      margin-left: 0
      .wrapper
        opacity: .6
        transition: opacity .5s ease-in-out


  &.top
    left: 0
    top: 0
    right: 0
    //border-bottom-left-radius: $border-radius-big
    //border-bottom-right-radius: $border-radius-big
    background: #fff
    padding: 1rem