@import "../../theme/theme"

.bigAvatar
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: 1rem

  img
    border: .4rem solid $ink-color
    object-fit: cover


.bigAvatarName, .bigAvatarImg
  width: 6rem
  height: 6rem
  border-radius: 3rem
  margin-bottom: .5rem

.bigAvatarName
  background-color: $ink-color
  display: flex
  justify-content: center
  align-items: center
  color: $white
  font-size: 1.5rem
  font-weight: bold

.bigAvatarText h5
  width: 18rem
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  text-align: center

.bigAvatarLink p
  font-size: .8rem
  font-weight: bold
  margin-top: -.5rem
