@import "../../theme/theme"

.alert
  position: fixed
  width: 100vw
  top: 0
  left: 0
  right: 0
  padding: 1rem
  backdrop-filter: blur(4px)

  min-height: 4rem
  z-index: 10

  .alertSuccess, .alertWarning, .alertError, .alertDefault
    background-color: #5897b9
    opacity: .8
    padding: 1rem
    border: none
    border-radius: 1rem
    text-align: center
    color: $white
    font-weight: 600
    //box-shadow: 0 .2rem .2rem $label-color


  .alertWarning
    background-color: darkred

  .alertError
    background-color: darkorange

  .alertDefault
    background-color: cornflowerblue
