@import "../../theme/theme"

.text, .multiply
  width: 100%
  height: 3.2rem

  span
    text-align: center
    margin: 0 auto .1rem auto
    width: 100%
    display: block
    color: $label-color
    font-weight: 600
    font-size: .9rem

.options
  background-color: $white
  padding: 0
  height: 0
  overflow: hidden
  opacity: 0
  transition: height .2s ease-in-out
  position: absolute
  width: 100%
  border-radius: .5rem
  box-shadow: 0 .5rem 1rem $shadow-color
  z-index: 1

  &.open
    height: auto
    opacity: 1
    padding: .3rem

  .option
    padding: 1em
    border-radius: .1rem

    &:active, &.active
      background-color: $border-color

.value
  height: 3.2rem
  width: 100%
  //background-color: $white
  display: flex
  align-items: center
  gap: 1rem
  justify-content: center

  img
    animation: unRotateImg .2s ease-in-out 1 forwards

  h3
    margin-bottom: 0

  &.open
    img
      animation: rotateImg .2s ease-in-out 1 forwards

@keyframes rotateImg
  from
    transform: rotate(0)

  to
    transform: rotate(-180deg)

@keyframes unRotateImg
  from
    transform: rotate(-180deg)

  to
    transform: rotate(0)

.button
  margin-top: 0
  .value
    border: 0.4rem solid rgba(0,0,0,0)
    input
      max-width: 5rem
      font-size: 2rem
      font-weight: bold
      border: none
      background: none
      text-align: center
      outline: none
  span
    font-weight: bold
    color: $label-color
    margin-bottom: .5rem
    margin-left: 1rem
    font-size: 0.9rem
    display: block
  .options
    max-height: 10rem
    overflow: auto
    width: calc(100vw - 2rem)

.notData
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: .3rem
  img
    width: 48px
    height: 48px

  p
    margin-top: 0
    opacity: .6