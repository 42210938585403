@import '../../theme/theme'

.accordion, .accordionMenu, .accordionContacts, .accordionMessage
  display: flex
  flex-direction: column


.item
  //border-bottom: 1px solid $border-color2
  padding: 0 1rem
  background-color: #fff
  border-radius: 1rem
  margin-top: 1rem

  &:last-child
    border-bottom: none

.accordionContacts
  .item
    border: none

.accordionMenu
  .item
    margin-top: 0
    background-color: #f1f1f1
    border: none
    padding: 0 1rem !important

    margin-bottom: .5rem
    border-radius: 1rem


.header
  display: flex
  align-items: center
  justify-content: space-between
  height: 4rem

.title
  display: flex
  align-items: center
  gap: 1rem

  .contacts
    opacity: .6
    font-size: .8rem
    font-weight: 600


.chevron
  transform: rotate(-90deg)
  animation: unRotateImg .2s ease-in-out 1 forwards
  transition: padding-bottom .2s ease-in-out

  &.active
    animation: rotateImg .2s ease-in-out 1 forwards

.content
  height: 0
  transition: height .2s ease-in-out
  overflow: hidden
  display: block

  .data
    padding: 0 0 1rem 0
    display: block

.accordionMenu
  .content
    display: none

  .contacts
    font-weight: 600

  .chevron
    animation: none

  .item
    padding: 0
    div
      font-size: .8rem
      font-weight: 600

  p
    opacity: .6

.accordionContacts
  .chevron
    display: none

  .content
    display: none

  .contacts
    display: flex
    align-items: center
    justify-content: space-between
    width: calc(100vw - 6rem)
    opacity: 1
    p
      margin: 0
      opacity: .6
      font-size: 0.8rem
      font-weight: 600
    a
      opacity: 1
      text-decoration: none
      font-weight: 500
      color: $ink-color

.accordionMessage
  .contacts
    display: flex
    align-items: center
    justify-content: space-between
    opacity: .6
    font-size: .8rem

  .activeTitle
    opacity: 1
    font-weight: 600

  p
    color: $background-btn !important

@keyframes rotateImg
  from
    transform: rotate(-90deg)

  to
    transform: rotate(0)

@keyframes unRotateImg
  from
    transform: rotate(0)

  to
    transform: rotate(-90deg)