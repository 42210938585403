@import "../../theme/theme"

.h1
  font-size: 3rem
  font-weight: bold
  margin-bottom: 2rem

.h2
  font-size: 2.5rem
  font-weight: bold
  margin-bottom: 2rem

.h3
  font-size: 2rem
  font-weight: bold
  margin-bottom: 1rem

.h4
  font-size: 1.5rem
  font-weight: bold
  margin-bottom: 1rem

.h5
  font-size: 1rem
  font-weight: bold
  margin-bottom: .5rem

.h6
  font-size: .8rem
  font-weight: bold

.p
  font-weight: normal
  font-size: 1rem
  color: $label-color
  margin-top: 1rem

  &:first-child
    margin-top: 0

.link
  color: $ink-color