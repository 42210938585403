@import "../../theme/theme"

.avatar
  display: flex
  gap: 1rem
  align-items: center

  h5
    margin-bottom: 0 !important
    width: 10rem
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.avatarImg, .avatarImgName
  width: 3rem
  height: 3rem
  border-radius: 50%
  background-color: #f1f1f1
  display: flex
  justify-content: center
  align-items: center
  object-fit: cover
  img
    width: 3rem
    height: 3rem
    border-radius: 50%
    object-fit: scale-down

.avatarImgName
  background-color: $ink-color
  color: $white
  font-weight: 600
