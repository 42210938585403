@import "../../theme/theme"

.input
  background-color: $white
  height: 3.2rem
  color: #222
  border: 1px solid #d3d3d3
  border-radius: 1rem
  width: 100%
  transition: background-color .2s ease-in-out
  padding: 0 1rem
  margin-bottom: 1rem

  &:disabled
    background-color: $background-disabled
    border-color: $background-disabled
.label
  width: 100%
  span
    margin-left: 1rem
    font-size: .9rem
    font-weight: bold
    margin-bottom: .5rem
    color: $label-color
    display: flex