@import "../../theme/theme"

.button
  display: flex
  align-items: center
  justify-content: center
  background-color: $background-btn
  border: none
  height: 3.2rem
  color: $white
  border-radius: 1rem
  width: 100%
  transition: background-color .2s ease-in-out

  &:active
    background-color: $background-active-btn

  &:disabled
    background-color: $background-disabled

.buttonLang
  width: 3rem
  height: 3rem
  border-radius: 50%
  border: none
  color: $white
  background-color: $background-btn
  text-transform: uppercase

  &.active
    color: $background-btn
    background-color: $white

  &:active
    background-color: $background-active-btn

  &:disabled
    background-color: $background-disabled

.buttonBlock
  display: flex
  gap: 1rem
  margin-top: 1rem

.gateRelay, .doorRelay
  height: auto
  width: 100%
  border-radius: 1.5rem
  background-color: #f1f1f1
  margin-bottom: .5rem
  padding: .5rem .5rem .5rem 1rem
  display: flex
  align-items: center
  justify-content: space-between
  //box-shadow: 0 0.1rem 0.1rem $shadow-color
  //border: 1px solid $shadow-color
  flex-direction: row
  gap: 1rem

  .gateRelayTitle, .doorRelayTitle
    display: flex
    gap: 1rem
    align-items: center

    img
      background-color: #fff
      width: 3rem
      height: 3rem
      padding: .7rem
      border-radius: 1rem
      margin-left: -.5rem

  button
    height: 3rem
    width: 3rem
    border: none
    background-color: $background-btn
    border-radius: 1rem
    transition: background-color .2s ease-in-out

    img
      width: 1.3rem
      height: 1.3rem
    &:active
      background-color: $background-active-btn

    &:disabled
      background-color: $background-disabled
      box-shadow: inset 0 0 .2rem rgba(0,0,0,.4)

  //&:first-child
  //  margin-top: 0
