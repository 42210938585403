@import "../../theme/theme"

.logout
  display: flex
  gap: 1rem
  align-items: center

  h5
    margin-bottom: 0 !important
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    font-weight: 500
    color: $label-color
    font-size: .8rem

.logoutImg
  border-radius: 50%
  background-color: $white
  display: flex
  justify-content: center
  align-items: center
  img
    width: 3rem
    height: 3rem
    border-radius: 50%
    object-fit: scale-down
