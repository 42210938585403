@import "../../theme/theme"

.group
  display: flex
  align-items: flex-end
  justify-content: space-between
  width: 100%

  label:first-child
    flex: 1

  label:last-child
    flex: 2

  &.horizontal
    flex-direction: column
    align-items: flex-start

  &.vertical
    align-items: flex-start
    flex-direction: column

    p
      font-size: .8rem
      opacity: .6
      margin-top: 0
      color: $ink-color !important