//$background-color: linear-gradient(#D7DCE6, #ffffff)
$background-color: #f1f1f1
$background-btn: #2c2e30
$background-active-btn: #1b1c1e
$white: #FFFFFF
$border-radius: 1.5rem
$border-radius-big: 2rem
$background-disabled: #e6e6e6
$border-color: #F4F6F9
$border-color2: #c4c9d0
$border-color3: #e2e5ea
$label-color: #697283
$ink-color: #ef6926
$shadow-color: #c1c4c7