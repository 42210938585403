@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
@import "./theme"

*
  padding: 0
  margin: 0
  box-sizing: border-box
  position: relative
  font-size: 16px
  color: $background-btn
  font-family: "Inter", sans-serif

body
  background: $background-color
  height: 100vh
  width: 100vw

.profile-page-img
  width: 100rem
  max-width: 100%
  height: 12rem
  border-radius: $border-radius
  background-color: #FFFFFF
  object-fit: cover
  margin-top: 1rem

.notActive
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  padding: 4rem

  h1
    width: 6rem
    height: 6rem
    background-color: $ink-color
    text-align: center
    color: $white
    line-height: 1
    display: flex
    align-items: center
    justify-content: center
    border-radius: 3rem

  h4
    text-align: center

  p
    text-align: center

.notData
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: .3rem
  img
    width: 48px
    height: 48px

  p
    margin-top: 0
    opacity: .6