@import '../../theme/theme'

.notification
  width: 3rem
  height: 3rem
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%

  span
    position: absolute
    width: auto
    min-width: 2rem
    height: 2rem
    background-color: $ink-color
    color: $white
    border-radius: 1rem
    z-index: 1
    display: flex
    align-items: center
    justify-content: center
    top: -.5rem
    left: -1.5rem
    font-size: .8rem
    font-weight: bold